var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "list-wrap" },
        [
          _c(
            "vo-pages",
            {
              attrs: { data: _vm.list, loadedAll: _vm.loadedAll },
              on: { pullingUp: _vm.pullingUp, pullingDown: _vm.pullingDown },
            },
            [
              _c(
                "ul",
                { staticClass: "article-list" },
                _vm._l(_vm.list, function (article) {
                  return _c("li", { key: article.id, staticClass: "article" }, [
                    _c("div", { staticClass: "left" }, [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: article.thumb_url,
                            expression: "article.thumb_url",
                          },
                        ],
                        attrs: { alt: article.name },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "right",
                        on: {
                          click: function ($event) {
                            return _vm.detail(article)
                          },
                        },
                      },
                      [
                        _c("p", [_vm._v(_vm._s(article.title))]),
                        _vm._v(" "),
                        _c("p", { staticClass: "more-info" }, [
                          _c("span", { staticClass: "time" }, [
                            _vm._v("发布时间：" + _vm._s(article.created_at)),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("footer-tabbar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }