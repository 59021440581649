var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-checkbox-group",
        {
          staticClass: "card-goods",
          model: {
            value: _vm.checkedGoods,
            callback: function ($$v) {
              _vm.checkedGoods = $$v
            },
            expression: "checkedGoods",
          },
        },
        _vm._l(_vm.goods, function (item) {
          return _c(
            "van-checkbox",
            {
              key: item.id,
              staticClass: "card-goods__item",
              attrs: { name: item.id },
            },
            [
              _c("van-card", {
                attrs: {
                  title: item.title,
                  desc: item.desc,
                  num: item.num,
                  price: _vm.formatPrice(item.price),
                  thumb: item.thumb,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("van-submit-bar", {
        attrs: {
          price: _vm.totalPrice,
          disabled: !_vm.checkedGoods.length,
          "button-text": _vm.submitBarText,
        },
        on: { submit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }