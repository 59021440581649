var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: { title: "双录资料填写", "left-text": "返回", "left-arrow": "" },
        on: { "click-left": _vm.onClickLeft },
      }),
      _vm._v(" "),
      _c(
        "van-row",
        { staticClass: "input-group" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.goods.name) + "-信息填写")]),
          _vm._v(" "),
          _c(
            "van-cell-group",
            {},
            _vm._l(_vm.goods.fieldmatch, function (item, key) {
              return _c(
                "van-row",
                [
                  item.field.type == 0
                    ? _c("van-field", {
                        attrs: {
                          required: !!item.is_must,
                          label: item.field.name,
                          placeholder: "请输入" + item.field.name,
                          "error-message": _vm.errorMsg.name,
                        },
                        model: {
                          value: _vm.data["field_" + key],
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "field_" + key, $$v)
                          },
                          expression: "data['field_'+key]",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.field.type == 1
                    ? _c("van-field-select-picker", {
                        attrs: {
                          required: !!item.is_must,
                          label: item.field.name,
                          placeholder: "请输入" + item.field.name,
                          columns: item.field.options,
                        },
                        model: {
                          value: _vm.data["field_" + key],
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "field_" + key, $$v)
                          },
                          expression: "data['field_'+key]",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.field.type == 3
                    ? _c("van-field-select-date", {
                        attrs: {
                          required: !!item.is_must,
                          label: item.field.name,
                          placeholder: "请选择" + item.field.name,
                        },
                        model: {
                          value: _vm.data["field_" + key],
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "field_" + key, $$v)
                          },
                          expression: "data['field_'+key]",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.field.type == 2
                    ? _c("van-field-select-picker", {
                        attrs: {
                          required: !!item.is_must,
                          label: item.field.name,
                          placeholder: "请选择" + item.field.name,
                          columns: item.field.options,
                        },
                        model: {
                          value: _vm.data["field_" + key],
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "field_" + key, $$v)
                          },
                          expression: "data['field_'+key]",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "van-button",
            {
              staticClass: "btn",
              attrs: { type: "info", size: "large" },
              on: { click: _vm.submit },
            },
            [_vm._v("完成")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }